<script>
//import { required } from "vuelidate/lib/validators";

import Layout from "@/views/layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Choices from "choices.js";

import { getAccountingGLApi } from '@/api/accounting/query'



/**
 * User list component
 */
export default {
  page: {
    title: "General Ledger List",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  data() {
    return {
      listingData: [],
      title: "General Ledger List",
      items: [
        {
          text: "Setting",
          href: "/",
        },
        {
          text: "General Ledger",
          active: true,
        },
      ],
      totalRows: 0,
      currentPage: 1,
      perPage: 100,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "value",
      sortDesc: false,
      fields: [

        {
          key: "REF",
          label: 'REF-ID',
          sortable: true,
        },

        {
          key: "Type",
          sortable: true,
        },
        {
          key: "Number",
          sortable: true,
        },

        {
          key: "Date",
          sortable: true,
        },
        {
          key: "Description",
          sortable: true,
        },
        { key: 'Action', label: '' }
      ],

      glList: [],
      ref_id: '',
      ref_type: 'ref_id'

    };
  },
  components: {
    Layout,
    PageHeader,

  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.totalRows
    },
  },

  created() {

  },
  mounted() {
    new Choices('#query_type', {
      placeholderValue: "search account",
      searchPlaceholderValue: "search by name",
      
    })

  },
  methods: {

    queryRefList() {


      if (!this.ref_id) {
        this.$alertify.error("Please Input Filter Condition ");
        return;
      }
      let data = {
       
        page: {
          page_no: this.currentPage,
          page_size: this.perPage
        }
      }

      data[this.ref_type] = this.ref_id

      getAccountingGLApi().ref_query(data).then(res => {
        if (res.errCode == 0) {
          this.listingData = []
          res.data.map(e => this.listingData.push(e))
          this.totalRows = res.page.total
        }
      })
    },

    pageChange(p) {
      this.currentPage = p
      this.queryRefList()
    },

    glDetail(refItem) {

      getAccountingGLApi().ref_gl_detail({ ref_id: refItem.id }).then(res => {
        if (res.errCode == 0) {
          this.glList = []
          res.data.map(gl => {
            this.glList.push(gl)
          })
          this.$bvModal.show('modal-gl-detail-dialog')
        } else {
          this.$alertify.error("Query Ref Detail Failed " + res.errCode);
        }
      })

    },

    close_gl_detail_dialog() {
      this.$bvModal.hide('modal-gl-detail-dialog')
    }


  },
  validations: {
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="row">

              <div class="col-md-2">
                <label>Query Type </label>
                <select id="query_type" v-model="ref_type">
                  <option value="ref_id" selected>Ref</option>
                  <option value="deal_id" selected>Deal</option>
                  <option value="amount" selected>Amount</option>
                </select>
              </div>

              <div class="col-md-3">
                <label> Query Id</label>
                <input class="form-control" v-model="ref_id" placeholder="Search by  Ref Id"
                  @keyup.enter="queryRefList" />
              </div>

              <div class="col-md-4 gap-2 mb-3 gap-2 mt-4">
              </div>

              <div class="col-md-2 gap-2 mb-3 gap-2 mt-4">
                <div class="d-flex flex-wrap align-items-start justify-content-md-end mt-2 mt-md-0 gap-2 mb-3">
                  <b-button variant="primary" @click="queryRefList">Query</b-button>

                </div>
              </div>

            </div>
            <!-- end row -->
            <b-modal centerd :id="'modal-gl-detail-dialog'" title="General Ledger Detail" size="lg" hide-footer>
              <div class="table-responsive mt-3" style="min-height: 20dvh;">
                <div class="table align-middle table-nowrap">
                  <b-table :items="glList" :fields="['Account', 'Amount', 'Description']" responsive="sm" :per-page="10"
                    class="table-check">
                    <template #cell(Account)="data">
                      <div class="row icon-demo-content-10">
                        <div class="col-xl-3 col-lg-4 col-sm-6">{{ data.item.gl_account }}</div>
                      </div>
                    </template>
                    <template #cell(Amount)="data">
                      <div class="row icon-demo-content-10">
                        <div class="col-xl-3 col-lg-4 col-sm-6">{{ data.item.amount }}</div>
                      </div>
                    </template>
                    <template #cell(Description)="data">
                      <div class="row icon-demo-content-10">
                        <div class="col-xl-3 col-lg-4 col-sm-6">{{ data.item.description }}</div>
                      </div>
                    </template>

                  </b-table>
                </div>
              </div>
              <!-- end table responsive -->

              <div class="row">
                <div class="col-md-4">

                </div>
                <div class="col-md-4">

                  <b-button variant="secondary" type="button" @click="close_gl_detail_dialog">Close</b-button>
                </div>
                <div class="col-md-4">

                </div>

              </div>
              <!-- end row-->

            </b-modal>


            <div class="table-responsive">
              <div class="table align-middle table-nowrap">
                <b-table :items="listingData" :fields="fields" responsive="sm" :per-page="perPage" class="table-check">
                  <template #cell(REF)="data">
                    {{ data.item.ref_sid }}
                  </template>
                  <template #cell(Type)="data">
                    {{ data.item.ref_type }}
                  </template>
                  <template #cell(Number)="data">
                    {{ data.item.cash_bearer_number }}
                  </template>
                  <template #cell(Date)="data">
                    {{ data.item.date }}
                  </template>
                  <template #cell(Description)="data">
                    {{ data.item.remarks }}
                  </template>
                  <template #cell(Action)="data">
                    <b-button variant="primary" @click="glDetail(data.item)">GL Detail</b-button>
                  </template>

                </b-table>
              </div>
            </div>
            <!-- end table responsive -->

            <div class="row g-0 text-center text-sm-start">
              <!-- end col -->
              <div class="col-sm-12">
                <div class="row">
                  <div class="col">
                    <div class="dataTables_paginate paging_simple_numbers float-end">
                      <!-- pagination -->
                      <b-pagination class="pagination-rounded" v-model="currentPage" :total-rows="totalRows"
                        :per-page="perPage" @change="pageChange"></b-pagination>
                    </div>
                  </div>
                </div>
              </div>
              <!-- end col -->
            </div>
            <!-- end row -->
          </div>
          <!-- end card body -->
        </div>
        <!-- end card -->
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->
  </Layout>
</template>
